<template>
  <div
    :style="styleProps"
    class="dashboard-card"
  >
    <div
      v-if="value !== null"
      class="dashboard-card__value"
    >
      {{ value }}
    </div>
    <div
      v-if="header"
      class="dashboard-card__header"
    >
      <slot
        name="header"
        :header="header"
      >
        {{ header }}
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      required: true,
    },
    header: {
      type: String,
      default: null,
    },
    value: {
      type: [Number, String],
      default: null,
    },
  },
  computed: {
    styleProps () {
      return {
        '--dashboard-card--color': this.color,
      }
    },
  },
}
</script>

<style lang="scss">
.dashboard-card {
  --dashboard-card--shadow: 0 4px 10px 0 #dbdbdb;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $mds-space-5-x;
  background-color: $mds-background-color-white;
  border-radius: $mds-border-radius-panel;
  box-shadow: var(--dashboard-card--shadow);
  border-top: 6px solid var(--dashboard-card--color);
}

.dashboard-card__value {
  @include mds-level-1-heading();
  margin: 0 0 $mds-space-1-x 0;
}

.dashboard-card__header {
  @include mds-level-6-heading($bold: true);
  margin: 0;
}
</style>
