<script>
export default {
  functional: true,
  render (h, { data, slots }) {
    return h(
      'div',
      { ...data, class: 'dashboard-card-group' },
      slots().default,
    )
  },
}
</script>

<style lang="scss">
// TODO: make responsive by stacking
.dashboard-card-group {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  gap: $mds-space-5-x;
}
</style>
