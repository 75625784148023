<template>
  <mds-loader v-if="importDetailsLoading" />
  <content-template
    v-else
    :back="true"
    :heading="FileName"
    @back="handleBack"
  >
    <template v-slot:title>
      <heading :level="1">
        {{ FileName }}
      </heading>
    </template>
    <content-container
      v-if="showBlankStatePage"
      title="Warning"
      :description="errorMessageDisplay"
      class="operations-historical-detail__blank-page"
    />
    <content-container
      v-else
      title="File Details"
      class="operations-historical-detail"
      :file-type-tag="setFileTypeTag"
    >
      <template v-slot:action>
        <mds-button
          id="download-files-action"
          variation="secondary"
          icon-right="caret-down"
          type="button"
          @click="openDownloadPopover"
        >
          Actions
        </mds-button>
        <mds-popover
          ref="popoverRef"
          v-model="displayDownloadPopover"
          :heading-level="5"
          position="bottom-left"
          title="Actions"
          title-hidden
          triggered-by="download-files-action"
          class="operations-historical-detail__popover"
        >
          <mds-button
            v-if="isSaved === null"
            variation="flat"
            type="button"
            @click="openModal"
          >
            Save Detail Page
          </mds-button>
          <template v-else>
            <mds-button
              variation="flat"
              type="button"
              @click="triggerModalRemovePageDialog"
            >
              Remove Detail Page
            </mds-button>
            <mds-button
              variation="flat"
              type="button"
              @click="triggerModalEditPageDialog"
            >
              Edit Detail Page Name
            </mds-button>
          </template>
          <mds-button
            v-if="importDetails.sourcefiles"
            variation="flat"
            type="button"
            class="operations-historical-detail__popover-action"
            @click="downloadSourcefiles(importDetails.id)"
          >
            Download Source File(s) ({{ sourcefileExtension }})
          </mds-button>
          <mds-button
            v-if="filterValidTransformedFilename"
            variation="flat"
            type="button"
            class="operations-historical-detail__popover-action"
            @click="downloadTransformedFile(importDetails.id)"
          >
            Download Transformed File (.xml)
          </mds-button>
          <mds-button
            variation="flat"
            type="button"
            class="operations-historical-detail__popover-action"
            @click="downloadFileDetails"
          >
            Download File Details (.xlsx)
          </mds-button>
        </mds-popover>
      </template>
      <div class="operations-historical-detail__metadata">
        <span class="operations-historical-detail__metadata-item">Total Records: {{ totalRecords }}</span>
        <span class="operations-historical-detail__metadata-item">Processing Status: {{ processingStatus }}</span>
        <span class="operations-historical-detail__metadata-item">
          File Status:
          <span
            v-if="validationStatus"
            :style="`--status-color: var(--color--${validationStatus.split(' ').join('-').toLowerCase()});`"
            class="operations-historical-detail__metadata-status"
          >
            {{ validationStatus }}
          </span>
          <template v-else>
            —
          </template>
        </span>
        <span class="operations-historical-detail__metadata-item">Received: {{ uploadDate }}</span>
        <span class="operations-historical-detail__metadata-item">File Extension: {{ fileExtension }}</span>
        <span class="operations-historical-detail__metadata-item">Delivery Method: {{ deliveryMethod }}</span>
        <span class="operations-historical-detail__metadata-item">Fiduciary: {{ fiduciary }}</span>
        <span class="operations-historical-detail__metadata-item">Processing Time: {{ processingTime }}</span>
        <span
          v-if="isPlansFileType"
          class="operations-historical-detail__metadata-item"
        >
          QE Date: {{ quarterEndDate }}
        </span>
      </div>
      <dashboard-card-group
        v-if="!schemaFailed"
        class="operations-historical-detail__overview"
      >
        <dashboard-card
          :value="totalRecordsFailed"
          header="Failed Records"
          color="var(--color--failed)"
        >
          <template v-slot:header="{ header }">
            <span class="operations-historical-detail__dashboard-header">{{ header }}</span>
          </template>
        </dashboard-card>
        <dashboard-card
          :value="totalRecordsSuccessful"
          header="Successful Records"
          color="var(--color--success)"
        >
          <template v-slot:header="{ header }">
            <span class="operations-historical-detail__dashboard-header">{{ header }}</span>
          </template>
        </dashboard-card>
      </dashboard-card-group>
      <section
        v-if="schemaFailed"
        class="operations-historical-detail__result-section"
        style="--status-color: var(--color--schema-failed);"
      >
        <heading
          :level="3"
          class="operations-historical-detail__result-section-heading"
        >
          Schema Failed
        </heading>
        <section
          v-if="schemaFailureSection.length"
          class="operations-historical-detail__result-section"
          style="--status-color: var(--color--schema-failed);"
        >
          <mds-section
            v-for="(section, index) in schemaFailureSection"
            :key="index"
            :bold="true"
            :collapsible="false"
            :expanded="index === 0"
            :heading-level="4"
            :size="6"
            :title="section.title"
            class="operations-historical-detail__result-sub-section"
          >
            <mds-data-table
              :header-configs="section.headers"
              :row-data="section.paginatedData"
              :responsive="section.responsive"
              :pagination-config="tablePaginationConfig(section.data)"
              pagination="below"
              @mds-data-table-page-change="handleSectionTablePageChange(section.id, $event)"
            />
          </mds-section>
        </section>
        <p
          v-else
          class="operations-historical-detail__result-section-description"
        >
          {{ importDetails.schemaErrorMessage }}
        </p>
      </section>
      <template v-else>
        <section
          v-if="failedRecordSections.length"
          class="operations-historical-detail__result-section"
          style="--status-color: var(--color--failed);"
        >
          <heading
            :level="3"
            class="operations-historical-detail__result-section-heading"
          >
            {{ failedRecordsLabel }}
          </heading>
          <mds-section
            v-for="(section, index) in failedRecordSections"
            :key="index"
            :bold="true"
            :collapsible="true"
            :expanded="index === 0"
            :heading-level="4"
            :size="6"
            :title="section.title"
            class="operations-historical-detail__result-sub-section"
          >
            <mds-data-table
              :header-configs="section.headers"
              :row-data="section.paginatedData"
              :responsive="section.responsive"
              :pagination-config="tablePaginationConfig(section.data)"
              pagination="below"
              @mds-data-table-page-change="handleSectionTablePageChange(section.id, $event)"
            />
          </mds-section>
        </section>
        <section
          v-if="successfulRecordSections.length"
          class="operations-historical-detail__result-section"
          style="--status-color: var(--color--success);"
        >
          <heading
            :level="3"
            class="operations-historical-detail__result-section-heading"
          >
            {{ successfulRecordsLabel }}
          </heading>
          <mds-section
            v-for="(section, index) in successfulRecordSections"
            :key="index"
            :bold="true"
            :collapsible="true"
            :expanded="index === 0"
            :heading-level="4"
            :size="6"
            :title="section.title"
            class="operations-historical-detail__result-sub-section"
          >
            <mds-data-table
              :header-configs="section.headers"
              :row-data="section.paginatedData"
              :responsive="section.responsive"
              :pagination-config="tablePaginationConfig(section.data)"
              pagination="below"
              @mds-data-table-page-change="handleSectionTablePageChange(section.id, $event)"
            />
          </mds-section>
        </section>
      </template>
      <!-- Modal  -->
      <template v-if="isSaved === null">
        <dashboard-modal-save
          v-model="inputMyDetailsName"
          :toggle-modal="toggleModal"
          :disable-save-btn="disableSaveBtn"
          title="Save Details Page"
          description="Save this Details Page to add it to the &quot;My Details&quot; section of your Dashboard."
          @close-modal="closeModal"
          @action-modal-button="actionModalButton"
        />
      </template>
      <template v-else>
        <mds-dialog
          v-model="toggleDialog"
          action-required
          :width="dialogWidth"
        >
          <dashboard-dialog-edit-content
            v-if="editDialogOpen"
            v-model="inputMyDetailsName"
            dialog-title="Edit Details Page"
            dialog-description="This will appear in &quot;My Details&quot; section of the Dashboard"
          />
          <dashboard-dialog-delete-content
            v-if="removeDialogOpen"
            :dialog-title="activeRemoveTitle"
          />
          <template v-slot:mds-dialog-actions-right>
            <dashboard-dialog-buttons
              :dialog-cancel-button-text="dialogCancelButtonText"
              :dialog-action-button-text="dialogActionButtonText"
              :disable-dialog-save-btn="disableSaveBtn"
              @close-dialog="closeDialog"
              @action-dialog-button="actionDialogButton"
            />
          </template>
        </mds-dialog>
      </template>
    </content-container>
  </content-template>
</template>

<script>
import { isEmpty, isObject } from 'lodash'
import { format, parseISO, endOfQuarter } from 'date-fns'
import { MdsButton } from '@mds/button'
import { MdsDataTable } from '@mds/data-table'
import MdsLoader from '@mds/loader'
import MdsPopover from '@mds/popover'
import MdsSection from '@mds/section'
import MdsDialog from '@mds/dialog'
import ContentTemplate from '../../components/ContentTemplate/ContentTemplate'
import ContentContainer from '../../components/ContentContainer/ContentContainer'
import Heading from '../../components/Heading/Heading'
import DashboardCardGroup from '../../components/DashboardCard/DashboardCardGroup'
import DashboardCard from '../../components/DashboardCard/DashboardCard'
import DashboardModalSave from '../../components/DashboardModalSave/DashboardModalSave'
import DashboardDialogDeleteContent from '../../components/DashboardDialogContent/DashboardDialogDeleteContent'
import DashboardDialogEditContent from '../../components/DashboardDialogContent/DashboardDialogEditContent'
import DashboardDialogButtons from '../../components/DashboardDialogContent/DashboardDialogButtons'
import OperationsService from '../../services/operations-service'
import Spreadsheet from '../../../shared/services/spreadsheet-service'
import { RadarViews } from '../../../shared/constants/radar-views'
import RoleAccess from '../../services/role-access-service'
import { NETWORK_ONLY, CACHE_FIRST } from '../../graqhql/fetch-policies'
import { DATE_FORMAT, TIMESTAMP_FORMAT, DATE_TIME_FORMAT } from '../../../shared/constants/dates'
import { VALIDATION_STATUS_COPY_MAP, SCHEMA_FAILED } from '../../../shared/constants/validation-statuses'
import { CREATE_DASHBOARD_MY_DETAILS, DELETE_DASHBOARD_MY_DETAILS, UPDATE_DASHBOARD_MY_DETAILS_NAME } from '../../graqhql/mutations'
import {
  GET_DASHBOARD_IF_EXISTS_MY_DETAILS,
  GET_DASHBOARD_USER_ID,
  GET_IMPORT_DETAILS,
  GET_IMPORT_SOURCEFILES,
  GET_IMPORT_TRANSFORMED_FILE,
  GET_IS_LIVE_PROVIDER } from '../../graqhql/queries'

const PRODUCT_FUND_TABLE_HEADERS = [
  { fieldName: 'fundName', text: 'Fund Name', width: '250px' },
  { fieldName: 'fundCode', text: 'Fund Code', align: 'right', width: '100px' },
  { fieldName: 'secId', text: 'SecID', align: 'right', width: '100px' },
  { fieldName: 'cusip', text: 'CUSIP', align: 'right', width: '100px' },
  { fieldName: 'ticker', text: 'Ticker', align: 'right', width: '80px' },
  { fieldName: 'newSales', text: 'New Sales', align: 'right', width: '80px' },
  { fieldName: 'core', text: 'Core', align: 'right', width: '80px' },
  { fieldName: 'open', text: 'Open', align: 'right', width: '80px' },
  { fieldName: 'product', text: 'Product', align: 'right', width: '100px' },
  { fieldName: 'productNewSales', text: 'Prod. New Sales', align: 'right', width: '110px' },
  { fieldName: 'productCore', text: 'Prod. Core', align: 'right', width: '80px' },
  { fieldName: 'productOpen', text: 'Prod. Open', align: 'right', width: '80px' },
]

const PRODUCT_PLAN_TABLE_HEADERS = [
  { fieldName: 'planId', text: 'planId', width: '80px' },
  { fieldName: 'ein', text: 'EIN', width: '80px' },
  { fieldName: 'pn', text: 'PN', width: '80px' },
  { fieldName: 'planName', text: 'Plan Name', width: '250px' },
  { fieldName: 'programId', text: 'ProgramId', width: '80px' },
  { fieldName: 'productId', text: 'ProductId', width: '150px' },
  { fieldName: 'enrolledMenuType', text: 'Menu Type', width: '100px' },
  { fieldName: 'startDate', text: 'Start Date', width: '100px' },
  { fieldName: 'endDate', text: 'End Date', width: '100px' },
  { fieldName: 'effectiveDate', text: 'Effective Date', width: '100px' },
]

const PRODUCT_PLAN_TABLE_HEADERS_SUCCESS = [
  { fieldName: 'clientFundCode', text: 'Client Fund Code', width: '150px' },
  { fieldName: 'fundAssets', text: 'Assets', width: '80px' },
  { fieldName: 'complianceEligible', text: 'Compliance Eligible', width: '120px' },
]

const MERGED_PLAN_TABLE_HEADERS = [...PRODUCT_PLAN_TABLE_HEADERS, ...PRODUCT_PLAN_TABLE_HEADERS_SUCCESS]

const RECORDS_PER_PROD_TABLE_HEADERS = [
  { fieldName: 'productName', text: 'Product Name' },
  { fieldName: 'records', text: 'Number of Records', align: 'right' },
]

const PLANS_PER_PROD_TABLE_HEADERS = [
  { fieldName: 'productId', text: 'ProductId' },
  { fieldName: 'records', text: 'Number of Plans', align: 'right' },
]

const SCHEMA_FAILURES_TABLE_HEADERS = [
  { fieldName: 'errorLocation', text: 'Error Location' },
  { fieldName: 'errorMessage', text: 'Error Message' },
]

// File detail Excel headers
const PRODUCT_FUND_EXCEL_HEADERS = PRODUCT_FUND_TABLE_HEADERS.map(({ fieldName, text }) => ({ key: fieldName, header: text }))
const RECORDS_PER_PROD_EXCEL_HEADERS = RECORDS_PER_PROD_TABLE_HEADERS.map(({ fieldName, text }) => ({ key: fieldName, header: text }))
const PRODUCT_PLAN_EXCEL_HEADERS = MERGED_PLAN_TABLE_HEADERS.map(({ fieldName, text }) => ({ key: fieldName, header: text }))
const RECORDS_PER_PLAN_EXCEL_HEADERS = PLANS_PER_PROD_TABLE_HEADERS.map(({ fieldName, text }) => ({ key: fieldName, header: text }))
const SUMMARY_EXCEL_HEADERS = [
  { key: 'fileType', header: 'File Type' },
  { key: 'fiduciary', header: 'Fiduciary' },
  { key: 'providerCode', header: 'Provider Code' },
  { key: 'deliveryMethod', header: 'Delivery Method' },
  { key: 'totalRecords', header: 'Total Records' },
  { key: 'failedRecords', header: 'Failed Records' },
  { key: 'successfulRecords', header: 'Successful Records' },
  { key: 'processingStatus', header: 'Processing Status' },
  { key: 'fileStatus', header: 'File Status' },
  { key: 'processingTime', header: 'Processing Time' },
  { key: 'createdDate', header: 'Record Created Date' },
  { key: 'createdBy', header: 'Record Created By' },
  { key: 'modifiedDate', header: 'Record Modified Date' },
  { key: 'modifiedBy', header: 'Record Modified By' },
]

const NUMBER_OF_ROWS_PER_PAGE_OPTIONS = [10, 20, 40, 80]
const NUMBER_OF_ROWS_PER_PAGE_DEFAULT = 20
const PLANS_TAG = 'Plans'
const FUND_UNIVERSE_TAG = 'Fund Universe'
const EMPTY_NEW_DETAILS_NAME = ''
const INITIAL_USER_ID = null

export default {
  components: {
    MdsButton,
    MdsDataTable,
    MdsLoader,
    MdsPopover,
    MdsSection,
    MdsDialog,
    ContentTemplate,
    ContentContainer,
    Heading,
    DashboardCardGroup,
    DashboardCard,
    DashboardDialogEditContent,
    DashboardDialogDeleteContent,
    DashboardDialogButtons,
    DashboardModalSave,
  },
  apollo: {
    userId: {
      query: GET_DASHBOARD_USER_ID,
      fetchPolicy: CACHE_FIRST,
    },
    isLiveProvider: {
      query: GET_IS_LIVE_PROVIDER,
      variables () {
        return {
          provider: this.$route.query.provider,
        }
      },
    },
    ifExistsMyDetails: {
      query: GET_DASHBOARD_IF_EXISTS_MY_DETAILS,
      variables () {
        return {
          options: {
            urlPath: window.location.href.split('#/')[1],
            userId: this.userId.id,
          },
        }
      },
      skip () {
        // if the user is not on apolloDB
        return this.userId === null
      },
      fetchPolicy: NETWORK_ONLY,
    },
    importDetails: {
      query: GET_IMPORT_DETAILS,
      variables () {
        return {
          id: this.$route.params.id,
          isPlansFileType: this.isPlansFileType,
          selectedView: this.selectedView,
        }
      },
      skip () {
        return this.skipImportDetailsQuery // This is preventing to run the query until check if a user is allowed to see the view
      },
      error (error) {
        this.authError = error // Error handling for unauthorized users
      },
      update: data => data.import,
      fetchPolicy: NETWORK_ONLY, // update fetch-policy to 'network-only' to get updated details after transformation service updates the record
    },
  },
  data () {
    return {
      authError: null,
      isLiveProvider: null,
      importDetails: null,
      displayDownloadPopover: false,
      sectionTablePageData: {},
      selectedView: RoleAccess.selectedView,
      toggleModal: false,
      toggleDialog: false,
      inputMyDetailsName: EMPTY_NEW_DETAILS_NAME,
      editDialogOpen: false,
      removeDialogOpen: false,
      userId: INITIAL_USER_ID,
      ifExistsMyDetails: null,
    }
  },
  computed: {
    dialogCancelButtonText () {
      return this.editDialogOpen ? 'Cancel' : 'No'
    },
    dialogActionButtonText () {
      return this.editDialogOpen ? 'Save' : 'Yes'
    },
    disableSaveBtn () {
      return this.editDialogOpen && this.inputMyDetailsName === EMPTY_NEW_DETAILS_NAME
    },
    importDetailsLoading () {
      return this.$apollo.queries.isLiveProvider.loading ||
      this.$apollo.queries.importDetails.loading ||
      this.$apollo.queries.ifExistsMyDetails.loading
    },
    dialogWidth () {
      return this.editDialogOpen ? '500px' : '350px'
    },
    isPlansFileType () {
      return this.$route.query.type === 'plans'
    },
    showBlankStatePage () {
      return ((this.selectedView === RadarViews.TEST) && (this.isLiveProvider?.value === true)) ||
      ((this.selectedView === RadarViews.LIVE) && (this.isLiveProvider?.value === false)) ||
      this.$route.query.provider === undefined ||
      this.importDetails === null ||
      !!this.authError
    },
    skipImportDetailsQuery () {
      return this.isLiveProvider === null ||
      this.$route.query.provider === undefined ||
      ((this.selectedView === RadarViews.TEST) && (this.isLiveProvider?.value === true)) ||
      ((this.selectedView === RadarViews.LIVE) && (this.isLiveProvider?.value === false))
    },
    errorMessageDisplay () {
      let errorMessage
      switch (true) {
        case !!this.authError || this.skipImportDetailsQuery:
          errorMessage = 'The user is not permitted to view this page based on their role or current data selection.'
          break
        default:
          errorMessage = 'There was an issue fetching historical details for this record. Please try again.'
      }
      return errorMessage
    },
    setFileTypeTag () {
      return this.isPlansFileType ? PLANS_TAG : FUND_UNIVERSE_TAG
    },
    isSaved () {
      if (this.ifExistsMyDetails !== null && this.ifExistsMyDetails.length !== 0) {
        return this.ifExistsMyDetails[0]
      }
      return null
    },
    activeRemoveTitle () {
      return `Are you sure you want to remove ${this.isSaved.detailName} from your Details?`
    },
    displayedFilename () {
      return this.importDetails?.displayedFilename
    },
    FileName () {
      return (this.isSaved !== null) ? this.isSaved.detailName + ' ☆' : this.displayedFilename
    },
    sourcefileExtension () {
      if (!this.importDetails.sourcefiles) {
        return null
      }
      const extension = this.importDetails.sourcefiles[0].name.split('.').pop() // We're assuming all files will be of the same type
      return `.${extension.toLowerCase()}`
    },
    filterValidTransformedFilename () {
      return !this.schemaFailed && this.importDetails.transformedFilename && this.importDetails.transformedFilename.includes('source/')
    },
    failedRecordsLabel () {
      if (this.isPlansFileType) {
        return 'Failed Plans'
      }
      return 'Failed Records'
    },
    successfulRecordsLabel () {
      if (this.isPlansFileType) {
        return 'Successful Plans'
      }
      return 'Successful Records'
    },
    totalRecords () {
      if (this.isPlansFileType) {
        return this.importDetails.totalPlans ?? '—'
      }
      return this.importDetails.totalRecords ?? '—'
    },
    totalRecordsFailed () {
      if (this.isPlansFileType) {
        return this.importDetails.totalPlansFailed ?? '—'
      }
      return this.importDetails.totalRecordsFailed ?? '—'
    },
    totalRecordsSuccessful () {
      if (this.isPlansFileType) {
        return this.importDetails.totalPlansSuccessful ?? '—'
      }
      return this.importDetails.totalRecordsSuccessful ?? '—'
    },
    processingTime () {
      return this.importDetails.processingTime || '—'
    },
    processingStatus () {
      return this.importDetails.processingStatus || '—'
    },
    uploadDate () {
      return this.importDetails.uploadDate ? format(parseISO(this.importDetails.uploadDate), DATE_TIME_FORMAT) : '—'
    },
    deliveryMethod () {
      return this.importDetails.uploadMethod || '—'
    },
    fiduciary () {
      return this.importDetails.fiduciary || '—'
    },
    fileExtension () {
      return this.importDetails.sourcefiles ? this.sourcefileExtension.split('.').pop().toUpperCase() : '—'
    },
    validationStatus () {
      return this.importDetails.validationStatus ? VALIDATION_STATUS_COPY_MAP[this.importDetails.validationStatus] : null
    },
    schemaFailed () {
      return this.importDetails.validationStatus === SCHEMA_FAILED
    },
    quarterEndDate () {
      return this.isPlansFileType && this.importDetails.quarterEndDate ? format(endOfQuarter(parseISO(this.importDetails.quarterEndDate)), DATE_FORMAT) : '—'
    },
    failedRecordSections () {
      if (this.isPlansFileType) {
        return [
          ...(this.invalidProductMenuCombinationSection ? [this.invalidProductMenuCombinationSection] : []),
        ]
      }
      return [
        ...(this.duplicateFundsSection ? [this.duplicateFundsSection] : []),
        ...(this.missingIdentifierFundsSection ? [this.missingIdentifierFundsSection] : []),
        ...(this.invalidProductIdentifierFundsSection ? [this.invalidProductIdentifierFundsSection] : []),
      ]
    },
    successfulRecordSections () {
      if (this.isPlansFileType) {
        return [
          ...(this.newPlansSection ? [this.newPlansSection] : []),
          ...(this.unknownPlanFundsSection ? [this.unknownPlanFundsSection] : []),
          ...(this.planFundsOutsideProductSection ? [this.planFundsOutsideProductSection] : []),
          ...(this.successfulPlansPerProductSection ? [this.successfulPlansPerProductSection] : []),
        ]
      }
      return [
        ...(this.conflictingFundsSection ? [this.conflictingFundsSection] : []),
        ...(this.unknownFundsSection ? [this.unknownFundsSection] : []),
        ...(this.successfulRecordsPerProductSection ? [this.successfulRecordsPerProductSection] : []),
      ]
    },
    schemaFailureSection () {
      return [
        ...(this.schemaFailuresMessagesSection ? [this.schemaFailuresMessagesSection] : []),
      ]
    },
    conflictingFundsExist () {
      return !!this.importDetails?.conflictingFunds?.length
    },
    conflictingFundsData () {
      if (!this.conflictingFundsExist) { return null }
      return this._constructProductFundTableData(this.importDetails.conflictingFunds)
    },
    conflictingFundsSection () {
      if (!this.conflictingFundsData) { return null }
      const id = 'conflictingFunds'
      const data = this.conflictingFundsData
      const pagination = this.sectionTablePageData[id]
      return this._constructRecordSection({ id, title: 'Conflicting Funds', data, pagination, headers: PRODUCT_FUND_TABLE_HEADERS })
    },
    duplicatingFundsExist () {
      return !!this.importDetails?.duplicateFunds?.length
    },
    duplicateFundsData () {
      if (!this.duplicatingFundsExist) { return null }
      return this._constructProductFundTableData(this.importDetails.duplicateFunds)
    },
    duplicateFundsSection () {
      if (!this.duplicateFundsData) { return null }
      const id = 'duplicateFunds'
      const data = this.duplicateFundsData
      const pagination = this.sectionTablePageData[id]
      return this._constructRecordSection({ id, title: 'Duplicate Funds', data, pagination, headers: PRODUCT_FUND_TABLE_HEADERS })
    },
    missingIdentifierFundsExist () {
      return !!this.importDetails?.missingIdentifierFunds?.length
    },
    missingIdentifierFundsData () {
      if (!this.missingIdentifierFundsExist) { return null }
      return this._constructProductFundTableData(this.importDetails.missingIdentifierFunds)
    },
    missingIdentifierFundsSection () {
      if (!this.missingIdentifierFundsData) { return null }
      const id = 'missingIdentifierFunds'
      const data = this.missingIdentifierFundsData
      const pagination = this.sectionTablePageData[id]
      return this._constructRecordSection({ id, title: 'Missing Identifier Funds', data, pagination, headers: PRODUCT_FUND_TABLE_HEADERS })
    },
    invalidProductIdentifierFundsExist () {
      return !!this.importDetails?.invalidProductIdentifierFunds?.length
    },
    invalidProductIdentifierFundsData () {
      if (!this.invalidProductIdentifierFundsExist) { return null }
      return this._constructProductFundTableData(this.importDetails.invalidProductIdentifierFunds)
    },
    invalidProductIdentifierFundsSection () {
      if (!this.invalidProductIdentifierFundsData) { return null }
      const id = 'invalidProductIdentifierFunds'
      const data = this.invalidProductIdentifierFundsData
      const pagination = this.sectionTablePageData[id]
      return this._constructRecordSection({ id, title: 'Invalid Product Identifier Funds', data, pagination, headers: PRODUCT_FUND_TABLE_HEADERS })
    },
    invalidProductMenuCombinationExist () {
      return !!this.importDetails?.invalidProductProgramMenuCombination?.length
    },
    invalidProductMenuCombinationData () {
      if (!this.invalidProductMenuCombinationExist) { return null }
      return this._constructProductPlanTableData(this.importDetails.invalidProductProgramMenuCombination)
    },
    invalidProductMenuCombinationSection () {
      if (!this.invalidProductMenuCombinationData) { return null }
      const id = 'invalidProductProgramMenuCombination'
      const data = this.invalidProductMenuCombinationData
      const pagination = this.sectionTablePageData[id]
      return this._constructRecordSection({ id, title: 'Unrecognized Menu Combination', data, pagination, headers: MERGED_PLAN_TABLE_HEADERS })
    },
    unknownFundsExist () {
      return !!this.importDetails?.unknownFunds?.length
    },
    unknownFundsData () {
      if (!this.unknownFundsExist) { return null }
      return this._constructProductFundTableData(this.importDetails.unknownFunds)
    },
    unknownFundsSection () {
      if (!this.unknownFundsData) { return null }
      const id = 'unknownFunds'
      const data = this.unknownFundsData
      const pagination = this.sectionTablePageData[id]
      return this._constructRecordSection({ id, title: 'Unknown Funds', data, pagination, headers: PRODUCT_FUND_TABLE_HEADERS })
    },
    newPlanRecordsExist () {
      return !!this.importDetails?.newPlanRecords?.length
    },
    newPlanRecordsData () {
      if (!this.newPlanRecordsExist) { return null }
      return this._constructProductPlanTableData(this.importDetails.newPlanRecords)
    },
    newPlansSection () {
      if (!this.newPlanRecordsData) { return null }
      const id = 'newPlanRecords'
      const data = this.newPlanRecordsData
      const pagination = this.sectionTablePageData[id]
      return this._constructRecordSection({ id, title: 'New Plans', data, pagination, headers: PRODUCT_PLAN_TABLE_HEADERS })
    },
    unknownPlanFundsExist () {
      return !!this.importDetails?.planFundsUnknown?.length
    },
    unknownPlanFundsData () {
      if (!this.unknownPlanFundsExist) { return null }
      return this._constructProductPlanTableData(this.importDetails.planFundsUnknown)
    },
    unknownPlanFundsSection () {
      if (!this.unknownPlanFundsData) { return null }
      const id = 'planFundsUnknown'
      const data = this.unknownPlanFundsData
      const pagination = this.sectionTablePageData[id]
      return this._constructRecordSection({ id, title: 'Unknown Plan Funds', data, pagination, headers: MERGED_PLAN_TABLE_HEADERS })
    },
    planFundsOutsideProductExist () {
      return !!this.importDetails?.planFundsOutsideProduct?.length
    },
    planFundsOutsideProductData () {
      if (!this.planFundsOutsideProductExist) { return null }
      return this._constructProductPlanTableData(this.importDetails.planFundsOutsideProduct)
    },
    planFundsOutsideProductSection () {
      if (!this.planFundsOutsideProductData) { return null }
      const id = 'planFundsOutsideProduct'
      const data = this.planFundsOutsideProductData
      const pagination = this.sectionTablePageData[id]
      return this._constructRecordSection({ id, title: 'Plan Funds not in Product', data, pagination, headers: MERGED_PLAN_TABLE_HEADERS })
    },
    successfulRecordsPerProductExist () {
      return this.importDetails &&
        this.importDetails.successfulRecordsPerProduct &&
        !isEmpty(this.importDetails.successfulRecordsPerProduct)
    },
    successfulRecordsPerProductData () {
      if (!this.successfulRecordsPerProductExist) { return null }
      return this._constructRecordsPerProductTableRow(this.importDetails.successfulRecordsPerProduct)
    },
    successfulRecordsPerProductSection () {
      if (!this.successfulRecordsPerProductData) { return null }
      const id = 'successfulRecordsPerProduct'
      const data = this.successfulRecordsPerProductData
      const pagination = this.sectionTablePageData[id]
      return this._constructRecordSection({ id, title: 'Number of Records Per Product', data, pagination, headers: RECORDS_PER_PROD_TABLE_HEADERS, responsive: false })
    },
    successfulPlansPerProductExist () {
      return this.importDetails &&
        this.importDetails.successfulPlansPerProduct &&
        !isEmpty(this.importDetails.successfulPlansPerProduct)
    },
    successfulPlansPerProductData () {
      if (!this.successfulPlansPerProductExist) { return null }
      return this._constructRecordsPerProductTableRow(this.importDetails.successfulPlansPerProduct)
    },
    successfulPlansPerProductSection () {
      if (!this.successfulPlansPerProductData) { return null }
      const id = 'successfulPlansPerProduct'
      const data = this.successfulPlansPerProductData
      const pagination = this.sectionTablePageData[id]
      return this._constructRecordSection({ id, title: 'Number of Plans Per Product', data, pagination, headers: PLANS_PER_PROD_TABLE_HEADERS, responsive: false })
    },
    schemaFailuresMessagesExist () {
      return this.importDetails &&
        this.importDetails.schemaFailures &&
        !isEmpty(this.importDetails.schemaFailures)
    },
    schemaFailuresMessagesData () {
      if (!this.schemaFailuresMessagesExist) { return null }
      const schemaFailuresParsed = typeof this.importDetails.schemaFailures === 'string' ? JSON.parse(this.importDetails.schemaFailures) : this.importDetails.schemaFailures
      return this._constructSchemaFailuresTableRow(schemaFailuresParsed)
    },
    schemaFailuresMessagesSection () {
      if (!this.schemaFailuresMessagesData) { return null }
      const id = 'schemaFailures'
      const data = this.schemaFailuresMessagesData
      const pagination = this.sectionTablePageData[id]
      return this._constructRecordSection({ id, title: 'Schema Failures', data, pagination, headers: SCHEMA_FAILURES_TABLE_HEADERS, responsive: false })
    },
  },
  methods: {
    openModal () {
      this.$refs.popoverRef.hide()
      this.toggleModal = true
    },
    closeModal () {
      this.toggleModal = false
      this.inputMyDetailsName = EMPTY_NEW_DETAILS_NAME
    },
    async createNewDetail () {
      this.toggleModal = false
      await this.$apollo.mutate({
        mutation: CREATE_DASHBOARD_MY_DETAILS,
        variables: {
          options: {
            userId: this.userId.id,
            urlPath: window.location.href.split('#/')[1],
            providerCode: this.$route.query.provider,
            fiduciaryCode: this.fiduciary,
            fileType: this.setFileTypeTag,
            detailName: this.inputMyDetailsName,
            detailType: 'File',
            selectedView: this.selectedView,
          },
        },
      }).then(() => {
        this.$notifications.success({ text: `${this.inputMyDetailsName} has been saved.`, tinted: true })
        this.inputMyDetailsName = EMPTY_NEW_DETAILS_NAME
        this.closeModal()
        this.$apollo.queries.ifExistsMyDetails.refetch()
      })
    },
    async updateMyDetailName () {
      this.toggleDialog = false
      await this.$apollo.mutate({
        mutation: UPDATE_DASHBOARD_MY_DETAILS_NAME,
        variables: {
          options: {
            id: this.isSaved.id,
            detailName: this.inputMyDetailsName,
          },
        },
      }).then(() => {
        this.$notifications.success({ text: `${this.inputMyDetailsName} has been saved.`, tinted: true })
        this.closeDialog()
        this.$apollo.queries.ifExistsMyDetails.refetch()
      })
    },
    async deleteMyDetail () {
      this.toggleDialog = false
      await this.$apollo.mutate({
        mutation: DELETE_DASHBOARD_MY_DETAILS,
        variables: {
          id: this.isSaved.id,
        },
      }).then(() => {
        this.$notifications.success({ text: `${this.isSaved.detailName} has been deleted from My Details.`, tinted: true })
        this.closeDialog()
        this.$apollo.queries.ifExistsMyDetails.refetch()
      })
    },
    actionModalButton () {
      this.createNewDetail()
    },
    actionDialogButton () {
      if (this.editDialogOpen === true) {
        this.updateMyDetailName()
      }
      if (this.removeDialogOpen === true) {
        this.deleteMyDetail()
      }
    },
    closeDialog () {
      this.toggleDialog = false
      if (this.editDialogOpen === true) {
        this.inputMyDetailsName = EMPTY_NEW_DETAILS_NAME
        this.editDialogOpen = false
      }
      if (this.removeDialogOpen === true) {
        this.removeDialogOpen = false
      }
    },
    triggerModalEditPageDialog () {
      this.$refs.popoverRef.hide()
      this.toggleDialog = true
      this.editDialogOpen = true
    },
    triggerModalRemovePageDialog () {
      this.$refs.popoverRef.hide()
      this.toggleDialog = true
      this.removeDialogOpen = true
    },
    handleSectionTablePageChange (tableId, sectionPageData) {
      this.sectionTablePageData = Object.assign({}, this.sectionTablePageData, { [tableId]: sectionPageData })
    },
    tablePaginationConfig (tableData) {
      return {
        pageSizes: NUMBER_OF_ROWS_PER_PAGE_OPTIONS,
        pageSize: NUMBER_OF_ROWS_PER_PAGE_DEFAULT,
        totalItems: tableData.length,
        showItemsInfo: true,
        showItemsSelect: true,
      }
    },
    handleBack () {
      this.$router.push({ name: 'operations-historical' })
    },
    openDownloadPopover () {
      this.displayDownloadPopover = true
    },
    async downloadSourcefiles (id) {
      const { data } = await this.$apollo.query({
        query: GET_IMPORT_SOURCEFILES,
        variables: { id, isPlansFileType: this.isPlansFileType, selectedView: this.selectedView },
        fetchPolicy: NETWORK_ONLY,
      })
      OperationsService.downloadFiles(data.import.sourcefiles)
    },
    async downloadTransformedFile (id) {
      const { data } = await this.$apollo.query({
        query: GET_IMPORT_TRANSFORMED_FILE,
        variables: { id, isPlansFileType: this.isPlansFileType, selectedView: this.selectedView },
        fetchPolicy: NETWORK_ONLY,
      })
      const { name, url } = data.import.transformedFile
      OperationsService.downloadFile(url, name)
    },
    async downloadFileDetails (id) {
      const timestamp = format(Date.now(), TIMESTAMP_FORMAT)
      const name = `${this.importDetails.fileType || 'File'} Details Export - ${this.displayedFilename} - ${timestamp}`
      const summaryData = this._constructFileDetailSummary(id)

      try {
        const spreadsheet = new Spreadsheet()
        spreadsheet.addSheet('Summary', SUMMARY_EXCEL_HEADERS, summaryData)

        if (this.isPlansFileType) {
          this._addSectionSheet(spreadsheet, 'Unrecognized Menu Combination', PRODUCT_PLAN_EXCEL_HEADERS, this.invalidProductMenuCombinationData, this.invalidProductMenuCombinationExist)
          this._addSectionSheet(spreadsheet, 'New Plans', PRODUCT_PLAN_EXCEL_HEADERS, this.newPlanRecordsData, this.newPlanRecordsExist)
          this._addSectionSheet(spreadsheet, 'Unknown Plan Funds', PRODUCT_PLAN_EXCEL_HEADERS, this.unknownPlanFundsData, this.unknownPlanFundsExist)
          this._addSectionSheet(spreadsheet, 'Plan Funds not in Product', PRODUCT_PLAN_EXCEL_HEADERS, this.planFundsOutsideProductData, this.planFundsOutsideProductExist)
          this._addSectionSheet(spreadsheet, 'Number of Plans Per Product', RECORDS_PER_PLAN_EXCEL_HEADERS, this.successfulPlansPerProductData, this.successfulPlansPerProductExist)
        } else {
          this._addSectionSheet(spreadsheet, 'Invalid Product Identifier Funds', PRODUCT_FUND_EXCEL_HEADERS, this.invalidProductIdentifierFundsData, this.invalidProductIdentifierFundsExist)
          this._addSectionSheet(spreadsheet, 'Missing Identifier Funds', PRODUCT_FUND_EXCEL_HEADERS, this.missingIdentifierFundsData, this.missingIdentifierFundsExist)
          this._addSectionSheet(spreadsheet, 'Duplicate Funds', PRODUCT_FUND_EXCEL_HEADERS, this.duplicateFundsData, this.duplicatingFundsExist)
          this._addSectionSheet(spreadsheet, 'Conflicting Funds', PRODUCT_FUND_EXCEL_HEADERS, this.conflictingFundsData, this.conflictingFundsExist)
          this._addSectionSheet(spreadsheet, 'Unknown Funds', PRODUCT_FUND_EXCEL_HEADERS, this.unknownFundsData, this.unknownFundsExist)
          this._addSectionSheet(spreadsheet, 'Number of Records Per Product', RECORDS_PER_PROD_EXCEL_HEADERS, this.successfulRecordsPerProductData, this.successfulRecordsPerProductExist)
        }
        const buffer = await spreadsheet.writeBuffer()
        this.$file.download(new Blob([buffer]), `${name}.xlsx`)
      } catch (err) {
        this.$notifications.error({ text: 'There was an issue downloading the file details. Please try again.', tinted: true })
      }
    },
    _addSectionSheet (spreadsheet, title, headers, sectionData, sectionExist) {
      if (!sectionExist) return null
      return spreadsheet.addSheet(title, headers, sectionData)
    },
    _constructRecordSection ({ id, title, data, headers, pagination = {}, responsive = true }) {
      const { firstItem, lastItem } = pagination
      const paginatedData = (firstItem && lastItem)
        ? data.slice(firstItem - 1, lastItem)
        : data.slice(0, NUMBER_OF_ROWS_PER_PAGE_DEFAULT) // firstItem and lastItem will be undefined until the MDS Data Table emits its pagination event when mounted
      return { id, title, data, headers, paginatedData, responsive }
    },
    _constructProductFundTableData (funds) {
      return funds.reduce((rows, fund, fundIndex) => {
        const { products } = fund
        const fundRows = products?.product.length
          ? products.product.map(product => this._constructProductFundTableRow({ fund, fundIndex, product }))
          : [this._constructProductFundTableRow({ fund, fundIndex })]
        return [
          ...rows,
          ...fundRows,
        ]
      }, [])
    },
    _constructProductPlanTableData (plans) {
      return plans.reduce((rows, plan, planIndex) => {
        const { fund = [] } = plan || {}
        const fundRows = fund.length
          ? fund.map(fund => this._constructProductPlanTableRow({ plan, planIndex, fund }))
          : [this._constructProductPlanTableRow({ plan, planIndex })]
        return [
          ...rows,
          ...fundRows,
        ]
      }, [])
    },
    _constructProductFundTableRow ({ fund, fundIndex, product }) {
      const fundId = fund.fundCode || fund.clientFundCode
      return {
        id: product?.productId ? `fund-${fundId}-product-${product.productId}` : `fund-${fundId}`,
        fundName: fund.fundName || '—',
        fundCode: fund.clientFundCode || '—',
        secId: fund.secId || '—',
        cusip: fund.cusip || '—',
        ticker: fund.ticker || '—',
        newSales: fund.isOpenNewSales ?? '—',
        core: fund.isCore ?? '—',
        open: fund.isOpen ?? '—',
        product: product?.productId || '—',
        productNewSales: product?.isOpenNewSales ?? '—',
        productCore: product?.isCore ?? '—',
        productOpen: product?.isOpen ?? '—',
        // Used for custom "zebraing" where each fund is grouped by the same color
        class: `operations-historical-detail__table-row operations-historical-detail__table-row--${fundIndex % 2 ? 'odd' : 'even'}`,
      }
    },
    _constructProductPlanTableRow ({ plan, planIndex, fund }) {
      const { startDate, endDate, effectiveDate } = plan || {}
      const planId = plan?.planId || fund?.clientFundCode
      const complianceEligible = !isEmpty(fund) && fund.complianceEligible ? fund.complianceEligible.toString().toUpperCase() : null
      const formattedStartDate = startDate && (isObject(startDate) ? format(new Date(`${startDate.month}/${startDate.day}/${startDate?.year}`), DATE_FORMAT) : format(parseISO(startDate), DATE_FORMAT))
      const formattedEndDate = endDate && (isObject(endDate) ? format(new Date(`${endDate.month}/${endDate.day}/${endDate.year}`), DATE_FORMAT) : format(parseISO(endDate), DATE_FORMAT))
      const formattedEffectiveDate = effectiveDate && (isObject(effectiveDate) ? format(new Date(`${effectiveDate.month}/${effectiveDate.day}/${effectiveDate.year}`), DATE_FORMAT) : format(parseISO(effectiveDate), DATE_FORMAT))

      return {
        id: plan?.productId ? `plan-${planId}-product-${plan.productId}` : `plan-${planId}`,
        planId: plan?.planId || '—',
        ein: plan?.ein || '—',
        pn: plan?.pn || '—',
        planName: plan?.planName || '—',
        programId: plan?.programId || '—',
        productId: plan?.productId || '—',
        enrolledMenuType: plan?.enrolledMenuType ?? '—',
        startDate: formattedStartDate ?? '—',
        endDate: formattedEndDate ?? '—',
        clientFundCode: fund?.clientFundCode ?? '—',
        effectiveDate: formattedEffectiveDate ?? '—',
        fundAssets: fund?.fundAssets ?? '—',
        complianceEligible: complianceEligible ?? '—',
        // Used for custom "zebraing" where each fund is grouped by the same color
        class: `operations-historical-detail__table-row operations-historical-detail__table-row--${planIndex % 2 ? 'odd' : 'even'}`,
      }
    },
    _constructRecordsPerProductTableRow (productMap) {
      return Object.entries(productMap).map(([key, value], index) => {
        if (this.isPlansFileType) {
          return { id: index, productId: key, records: value }
        }
        return { id: index, productName: key, records: value }
      })
    },
    _constructSchemaFailuresTableRow (failuresMap) {
      let failureIndex = 0
      let failuresArray = []
      Object.entries(failuresMap).map(([key, values]) => {
        failuresArray = values.reduce((rows, currentMessage) => {
          failureIndex = rows.length
          const newFailureRow = { id: failureIndex, errorLocation: key, errorMessage: currentMessage }
          return [...rows, newFailureRow]
        }, failuresArray)
      })
      return [...failuresArray]
    },
    _constructFileDetailSummary () {
      return [
        {
          fileType: this.importDetails.fileType || '—',
          fiduciary: this.importDetails.fiduciary || '—',
          providerCode: this.importDetails.provider || '—',
          totalRecords: this.totalRecords,
          failedRecords: this.totalRecordsFailed,
          successfulRecords: this.totalRecordsSuccessful,
          processingStatus: this.processingStatus,
          fileStatus: this.validationStatus,
          processingTime: this.processingTime,
          deliveryMethod: this.importDetails.uploadMethod || '—',
          createdDate: this.importDetails.uploadDate ? format(parseISO(this.importDetails.uploadDate), DATE_TIME_FORMAT) : '—',
          createdBy: this.importDetails.uploadBy || '—',
          modifiedDate: this.importDetails.modifiedDate ? format(parseISO(this.importDetails.modifiedDate), DATE_TIME_FORMAT) : '—',
          modifiedBy: this.importDetails.modifiedBy || '—',
        },
        {}, // Skip a couple rows before displaying the schema error message.
        {},
        ['Schema Error Message'],
        [this.importDetails.schemaErrorMessage || '—'],
      ]
    },
  },
}
</script>

<style lang="scss">
@mixin status-indicator {
  display: flex;
  align-items: center;

  &::before {
    content: '';
    width: 12px;
    height: 12px;
    margin-right: $mds-space-1-x;
    border-radius: $mds-border-radius-round;
    background-color: var(--status-color);
  }
}

.operations-historical-detail__popover{
  .mds-section__content___radar{
    padding: $mds-space-1-and-a-half-x 0;
  }
  display: flex;
    div {
      width: 300px;
      overflow-x: hidden;
    }
    .mds-button___radar {
      margin: $mds-space-quarter-x;
      padding: $mds-space-three-quarter-x $mds-space-1-and-a-half-x;
      width: 100%;
      text-align: left;
      &:hover, &:active, &:focus {
        background-color: $mds-background-color-light-gray;
      }
    }
}

.operations-historical-detail {
  --color--success: #009592;
  --color--partial: #{$mds-feedback-color-warning};
  --color--failed: #{$mds-feedback-color-error};
  --color--schema-failed: #{$mds-color-violet-46};
  --color--background-primary: #{$mds-background-color-white};
  --color--background-secondary: #{$mds-background-color-light-gray};
}

.operations-historical-detail__blank-page {
  text-align: center;
}

.operations-historical-detail__popover-action {
  display: block;

  &:not(:last-of-type) {
    margin: 0 0 $mds-space-half-x 0;
  }
}

.operations-historical-detail__metadata {
  @include mds-body-text-l($bold: true);
  margin: 0 0 $mds-space-5-x 0;
}

.operations-historical-detail__metadata-item {
  display: inline-flex;
  margin-bottom: $mds-space-half-x;

  &:not(:last-child) {
    padding-right: $mds-space-1-x;
    margin-right: $mds-space-1-x;
    border-right: $mds-border-separator-on-light;
  }
}

.operations-historical-detail__metadata-status {
  @include status-indicator(); // Status type set inline above
  margin-left: $mds-space-1-x;
}

.operations-historical-detail__dashboard-header {
  --status-color: var(--dashboard-card--color); // Revisit. Works, but shouldn't need to know internal custom property
  @include status-indicator();
}

.operations-historical-detail__overview {
  margin: 0 0 $mds-space-8-x 0;
}

.operations-historical-detail__result-section {
  &--failed {
    --status-color: var(--color--failed);
  }

  &--schema-failed {
    --status-color: var(--color--schema-failed);
  }

  &--successful {
    --status-color: var(--color--success);
  }

  // Revisit styles if this becomes a common pattern and move to ContentContainer
  &:not(:last-of-type) {
    padding-bottom: 80px;
  }

  &:nth-of-type(2) {
    margin: 0 -80px -80px;
    padding: 80px;
    background-color: var(--color--background-secondary);
    border-radius: 0 0 $mds-border-radius-panel $mds-border-radius-panel;

    // Flip table row background color order when displayed in the successful record section so secondary color displays first
    .operations-historical-detail__table-row--even {
      background-color: var(--color--background-secondary);
    }

    .operations-historical-detail__table-row--odd {
      background-color: var(--color--background-primary);
    }
  }
}

.operations-historical-detail__result-section-heading {
  @include status-indicator();
  @include mds-level-3-heading($bold: true);
  margin: 0 0 $mds-space-5-x 0;
}

.operations-historical-detail__result-section-description {
  margin: 0;
}

.operations-historical-detail__result-sub-section {
  &:not(:last-of-type) {
    margin: 0 0 $mds-space-4-x;
  }
}

.operations-historical-detail__table-row {
  &--even {
    background-color: var(--color--background-primary);
  }

  &--odd {
    background-color: var(--color--background-secondary);
  }
}
</style>

<style lang="scss" module>
// MDS overrides

/**
 * When there is a failed and successful record section, the successful record section
 * receives the secondary background color. This MDS override is to ensure the background color
 * of the section and table are consistent.
 */
:global(.operations-historical-detail__result-section:nth-of-type(2)) {
  .mds-data-table__responsive-wrap .mds-data-table--sticky-column {
    background-color: var(--color--background-secondary);
  }
}
</style>
