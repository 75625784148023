/**
 * A service for working with Excel documents.
 *
 * This service can be used in the browser and on the server though some
 * methods may browser or server-specific. Please note if this is the case.
 *
 * Please see the ExcelJS documentation: https://github.com/exceljs/exceljs
 */

import ExcelJS from 'exceljs'

class SpreadsheetService {
  constructor () {
    this.document = new ExcelJS.Workbook()
  }

  /**
   * Adds a "sheet" (or "tab") to the document.
   *
   * Note: currently assuming each sheet follows a typical table structure
   * of headers and rows. Should our needs change, this API can be
   * expanded on.
   *
   * @param {String} name
   * @param {Array} columns (https://github.com/exceljs/exceljs#columns)
   * @param {Array} rows (https://github.com/exceljs/exceljs#rows)
   */
  addSheet (name, columns = [], rows = []) {
    const sheet = this.document.addWorksheet(name)

    if (columns?.length > 0) {
      sheet.columns = columns
      sheet.columns.forEach(column => {
        // A naive "auto size" implementation where each header column is at least as wide as its content.
        column.width = column.header.length < 20 ? 20 : column.header.length
      })
    }

    if (rows?.length > 0) {
      rows.forEach(row => sheet.addRow(row))
    }

    return sheet
  }

  async writeBuffer () {
    return this.document.xlsx.writeBuffer()
  }
}

export default SpreadsheetService
