import RequestService from './utils/request-service'

class OperationsService {
  constructor () {
    this.request = new RequestService()
  }

  uploadImportFiles (files, onProgress) {
    const NUM_FILES = files.length
    const PROGRESS_OF_FILES = new Array(NUM_FILES).fill(0)

    const updateProgress = (fileIndex) => ({ loaded, total }) => {
      PROGRESS_OF_FILES[fileIndex] = Math.floor(loaded * 100) / total
      const totalProgress = Math.ceil(PROGRESS_OF_FILES.reduce((total, currProgress) => total + currProgress, 0) / NUM_FILES)
      onProgress(totalProgress)
    }

    const uploadRequests = files.map(({ file, url }, index) => {
      const uploadConfig = { onUploadProgress: updateProgress(index) }
      return this.request.put(url, file, { config: uploadConfig })
    })

    return Promise.all(uploadRequests)
  }

  // Abstract to utility function if needed elsewhere
  downloadFile (url, name) {
    const a = document.createElement('a')
    a.setAttribute('download', name)
    a.style.display = 'none'
    a.href = url
    document.body.appendChild(a)
    a.click()
    a.parentNode.removeChild(a)
  }

  downloadFiles (files) {
    const download = index => {
      if (index >= files.length) {
        return
      }
      this.downloadFile(files[index].url, files[index].name)
      setTimeout(() => download(index + 1), 1250)
    }
    download(0)
  }
}

export default new OperationsService()
